<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="dialog"
      max-width="800px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title> </v-card-title>
        <v-card-text>
          <v-row
            align="baseline"
            dense
            no-gutters
            v-for="(item, i) in wellstypes"
            :key="i"
          >
            <v-col dense cols="12" sm="4" md="4">
              <div :style="getStyle(item.id)">
                {{ item.label }}
              </div>
            </v-col>
            <v-col dense cols="12" sm="8" md="8">
              <v-select
                class="rowh0"
                outlined
                dense
                v-model="given['t_' + String(parseInt(1 + i))]"
                :items="given_action"
                item-value="id"
                item-text="description"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-1" color="blue darken-1" @click="save()">
            Save
          </v-btn>
          <v-btn class="ma-1" color="blue darken-1" @click="closeDialog()">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog></v-form
  >
</template>
<script>
import AddGivenAction from "../graphql/Tests/AddGivenAction.gql";
import UpdateGivenAction from "../graphql/Tests/UpdateGivenAction.gql";
export default {
  components: {},
  props: {
    dialog: Boolean,
    wellstypes: Array,
    given_action: Array,
    failure: Object,
    given: Object,
    welltype: String,
  },
  data() {
    return {
      kall: 1000,
    };
  },
  watch: {},
  mounted() {},
  computed: {},
  methods: {
    getStyle(type) {
      if (type == this.welltype)
        return "color: blue;text-decoration: underline;";
    },
    closeDialog(item) {
      this.$emit("close_given", item);
    },
    save() {
      if (this.given.id && this.given.id != null) {
        this.$apollo
          .query({
            query: UpdateGivenAction,
            variables: {
              fga: {
                id: this.given.id,
                failures: this.given.failures,
                t_1: this.given.t_1 != -1 ? this.given.t_1 : null,
                t_2: this.given.t_2 != -1 ? this.given.t_2 : null,
                t_3: this.given.t_3 != -1 ? this.given.t_3 : null,
                t_4: this.given.t_4 != -1 ? this.given.t_4 : null,
                t_5: this.given.t_5 != -1 ? this.given.t_5 : null,
                t_6: this.given.t_6 != -1 ? this.given.t_6 : null,
                t_7: this.given.t_7 != -1 ? this.given.t_7 : null,
                t_8: this.given.t_8 != -1 ? this.given.t_8 : null,
                t_9: this.given.t_9 != -1 ? this.given.t_9 : null,
                t_10: this.given.t_10 != -1 ? this.given.t_10 : null,
                t_11: this.given.t_11 != -1 ? this.given.t_11 : null,
                t_12: this.given.t_12 != -1 ? this.given.t_12 : null,
              },
            },
            fetchPolicy: "no-cache",
          })
          .then(() => {
            this.$emit("close_given", this.given);
          })
          .catch(() => {});
      } else {
        let r = this.$apollo.query({
          query: AddGivenAction,
          variables: {
            fga: this.given,
          },
          fetchPolicy: "no-cache",
        });
        if (r) {
          // this.given.id = r.data.data.createFailureGA.id;
          this.$emit("close_given", this.given);
        }
      }
    },
  },
};
</script>
<style scoped>
.rowh2 {
  width: 100% !important;
  height: 25px !important;
}
</style>
